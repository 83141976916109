import React from "react";
import reveal from "../assets/Reveal2.mp4";
import trophy from "../assets/Trophy.webm";
import trophyBackground from "../assets/EL0A9974.jpg";
import { Footer, Header } from "../components";
import "../styles/Home.css";

export default function Home() {
  return (
    <div id="pageContainer">
      <div id="contentWrap">
        <Header transparent={true} />
        <div id="videoMount">
          <video autoPlay muted loop id="reveal" src={reveal}></video>
          <div id="overlay">
            <div id="desc" title="WE ARE TEAM">
              WE ARE TEAM
            </div>
            <div id="title" title="82855">
              82855
            </div>
            {/* <div id="location">A VRC TEAM BASED IN RICHMOND HILL, ONTARIO</div> */}
          </div>
        </div>
      </div>
      <div id="gradient"></div>
      <div id="statWrapper">
        <div id="trophyCanvas">
          <video autoPlay muted loop id="trophyVideo" src={trophy}></video>
        </div>
        <div id="statContentWrapper">
          {/* <div id="mainHeading">Our Awards</div> */}
          <div className="stat" id="stat1">
            <div className="stat-number">19X</div>
            <div className="stat-text">WORLDS<br></br>QUALIFIED</div>
          </div>
          <div className="stat" id="stat2">
            <div className="stat-number">5X</div>
            <div className="stat-text">
              PROVINCIAL
              <br />
              FINALISTS
            </div>
          </div>
          <div className="stat" id="stat3">
            <div className="stat-number">2X</div>
            <div className="stat-text">
              PROVINCIAL
              <br />
              CHAMPIONS
            </div>
          </div>
          <div className="stat" id="stat4">
            <div className="stat-number">2X</div>
            <div className="stat-text">
              PROVINCIALS
              <br />
              SKILLS CHAMP
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
