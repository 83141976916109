import React from "react";
import "../styles/Sponsors.css";

export default function Sponsor(props: { title: string; logo: any; logoAlt: string; description: string; url: string }) {
    return (
        <div id="sponsor">
            <div id="highlightLine"></div>
            <a href={props.url} target="_blank" rel="noopener noreferrer" id="text" > {/* style={{ textDecoration: "none" }} */}
                {props.title}
            </a>
            <img src={props.logo} alt={props.logoAlt} id="sponsorLogo" />
            <p id="text">{props.description}</p>
        </div>
    );
}
