import React from "react";
import { Footer, Header } from "../components";
import "../styles/Sponsors.css";
// import logo from "../assets/Robotics_logo.svg"; // Temporary logo
import RWD from "../assets/RWD.svg";
import TDLogo from "../assets/TDLogo.png";
import PremformLogo from "../assets/Premform.svg";
import Cliffwood from "../assets/Cliffwood.png";
import { Sponsor } from "../components";

export default function Sponsors() {

    return (
        <div id="pageContainer">
            <div id="contentWrap">
                <div id="headerContainer">
                    <Header />
                </div>
                <h1 id="sponsorHeader">Our Sponsors</h1>
                <p id="tier1" >Tier 4</p>
                <div id="tier1Body">
                    <Sponsor title="RWD Tool & Machine" logo={RWD} logoAlt="RWD Logo" description="" url="https://rwdtool.com" />
                    <Sponsor title="Premform" logo={PremformLogo} logoAlt="Premform Logo" description="" url="https://www.premform.com" />
                </div>

                <div id="sponsorBody">
                    <div id="sponsorBlock">
                        <p id="otherTier">Tier 3</p>
                        <Sponsor title="Cliffwood Dental Office" logo={Cliffwood} logoAlt="Cliffwood Dental Logo" description="" url="https://cliffwooddental.com" />
                    </div>
                    <div id="sponsorBlock">
                        <p id="otherTier">Tier 2</p>
                        <Sponsor title="TD" logo={TDLogo} logoAlt="STL Robotics Logo" description="" url="https://td.com" />
                    </div>
                    {/* <div id="sponsorBlock">
                        <p id="otherTier">Tier 1</p>
                        <Sponsor title="STL Robotics" logo={logo} logoAlt="STL Robotics Logo" description="" url="" />
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>
    );
}
