import React from "react";
import { Footer, Header } from "../components";
import "../styles/About.css";
import worlds from "../assets/Worlds_banner.jpg";

export default function About() {

    return (
        <div>
            <div id="headerContainer">
                <Header transparent={false} />
            </div>
            <div id="bannerMount">
                <img id="banner" src={worlds} alt="worlds banner" />
                <h1 id="aboutTitle">About Us</h1>
            </div>
            <div id="aboutBody">
                <p>We are STL Robotics Team 82855, a group of fifty-seven enthusiastic students across six individual teams based out of St. Theresa of Lisieux CHS in Richmond Hill. We have dedicated ourselves for the past 8 years in the VEX Robotics Competition to compete across the world in competitions against the best of the best. We are the 2023 VEX Ontario Provincial Champions, 2024 VEX Ontario Provincial Finalists, and we are a 15-time VEX World Championships qualifying team, having placed 3rd and 4th in the world during the 2020-2021 season.</p>
            </div>
            <Footer />
        </div>
    );
}