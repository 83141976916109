import React from "react";
import { Footer, Header } from "../components";
import "../styles/Contact.css";

export default function Contact() {

    return (
        <div>
            <div id="headerContainer">
                <Header />
            </div>

            <div id="contactBody">
                <div id="contactInfo">
                    <h1 id="contactHeader">Contact us:</h1>
                    <p>Email: michael.morrison@ycdsb.ca</p>
                    <p>Phone: 905-787-1407 ext. 2505</p>
                    <h1 id="contactHeader">Make a donation:</h1>

                    <p>Visit <span id="clickSponsor" onClick={() => window.location.href = 'https://www.canadahelps.org/en/charities/york-catholic-district-school-board/'}>www.canadahelps.org</span>. Our charity is listed under the York Catholic District School Board and the specific fund is St. Theresa of Lisieux CHS. Sponsorships or donations help to support us in our efforts to further develop and enhance our robotics team</p>
                </div>
                <div id="map">
                    <div id="mapOuter">
                        <div id="gmapCanvas">
                            <iframe title="maps" width="700" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=230%20Shaftsbury%20Avenue&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}