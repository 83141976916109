import React from "react";
import "../styles/Footer.css";
import youtube from "../assets/Youtube.svg";
import instagram from "../assets/Instagram.svg";

export default function Footer() {
    return (
        <footer id="footerBanner">
            <div id="socialMedia">
                <p id="mediaTitle">Follow us on social media!</p>
                <div id="icons">
                    <a href="https://www.youtube.com/@stl_robotics">
                        <img src={youtube} alt="YouTube" />
                    </a>
                    <a href="https://www.instagram.com/stl_robotics">
                        <img src={instagram} alt="Instagram" />
                    </a>
                </div>
            </div>
        </footer>
    );
}