import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { Home, Sponsors, Contact, About } from './pages';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/sponsors",
    element: <Sponsors />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // removed strict mode because it messed w three.js rendering
    <RouterProvider router={router} />
);

