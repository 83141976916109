import React, { useEffect } from "react";
import "../styles/Header.css";
import logo from "../assets/Robotics_logo.svg";

export default function Header({ transparent = true }: { transparent?: boolean }) {

    useEffect(() => {

        const onScroll = () => {
            const combinedHeader = document.getElementById("combinedHeader");
            if (transparent) {
                if (window.scrollY > 30) {
                    combinedHeader?.classList.add("scrolled");
                } else {
                    combinedHeader?.classList.remove("scrolled");
                }
            }
        };

        window.addEventListener("scroll", onScroll);

        if (!transparent) {
            const headerMount = document.getElementById("headerMount");
            headerMount?.classList.add("notTransparent");
        }

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [transparent]);


    return (
        <div>
            {/* <h1 id="branding">82855</h1> */}
            <div id="combinedHeader" >
                <header id="mainHeader" >
                    <img src={logo} alt="STL Robotics Logo" id="lionsLogo" />
                    <h1>STL ROBOTICS</h1>
                </header>
                <div id="separator"></div>
                <ul id="sideHeader">
                    <a href="/">HOME</a>
                    <a href="/about">ABOUT US</a>
                    <a href="/contact">CONTACT</a>
                    <a href="/sponsors">OUR SPONSORS</a>
                </ul>
            </div>
        </div>
    );
}